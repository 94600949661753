import React from 'react';
import {Box,Stack} from '@mui/material'
import AccentBox from '../../Components/AccentBox';

function TopSection19(){

    const before_checkMark_blue={
        '&::before':{
            content:'""', 
            display:"inline-block", 
            verticalAlign:"middle", 
            mr:"12px", 
            width:"30px", 
            height:"30px", 
            minWidth:"30px",
            background:"url("+process.env.PUBLIC_URL+"/static/media/LP/checkbox1.webp) no-repeat",backgroundSize:"contain"
        }
    }

    return (
        <Box component="section" backgroundColor="#EBEBEB" pt={10} pb={10}>
            <Box className='section-content' >
                <Box component="h1" textAlign="center" fontSize={{xs:20, md:30}} fontWeight="bold">導入効果</Box>
                <Box margin="0 auto" width={80} height={2} backgroundColor="#4771DF" mt={2} mb={5}></Box>

                <Stack flexDirection={{sx:"column",md:"row"}} p={2} gap={5}>
                    <Stack flex={1} justifyContent="space-around" gap={2}>
                        
                        <Stack flexDirection="row" sx={before_checkMark_blue} alignItems="center" textAlign="left"><Box component="h3" fontSize={{xs:14, md:16}} fontWeight="bold">安心して採用できるようになり、<br/><AccentBox><Box component="span" fontSize={{xs:20, md:20}}>従業員数</Box>が<Box component="span" fontSize={{xs:20, md:20}}>２倍</Box></AccentBox>に！</Box></Stack>

                        <Stack flexDirection="row" sx={before_checkMark_blue} alignItems="center" textAlign="left"><Box component="h3" fontSize={{xs:14, md:16}} fontWeight="bold">何役も兼務していた社長の<Box component="br" display={{xs:"block",md:"none"}}/><Box component="span" fontSize={{xs:20, md:20}}><AccentBox>業務負担が軽減</AccentBox></Box>され、<br/><Box component="span" fontSize={{xs:20, md:20}}><AccentBox>本業に専念</AccentBox></Box>でできるようになった！</Box></Stack>

                        <Stack flexDirection="row" sx={before_checkMark_blue} alignItems="center" textAlign="left"><Box component="h3" fontSize={{xs:14, md:16}} fontWeight="bold">従業員の<Box component="br" display={{xs:"block",md:"none"}}/><AccentBox fontSize={{xs:20, md:20}}>働きやすい環境</AccentBox>を<Box component="br" display={{xs:"block",md:"none"}}/>作ることができた！</Box></Stack>

                        <Stack flexDirection="row" sx={before_checkMark_blue} alignItems="center" textAlign="left"><Box component="h3" fontSize={{xs:14, md:16}} fontWeight="bold">従業員のためになる制度などを導入できた上、助成金も受給！<br/>本当の意味で<AccentBox fontSize={{xs:20, md:20}}>会社の成長を支える土台作り</AccentBox>ができた！</Box></Stack>

                        <Stack flexDirection="row" sx={before_checkMark_blue} alignItems="center" textAlign="left"><Box component="h3" fontSize={{xs:14, md:16}} fontWeight="bold">会社の<AccentBox fontSize={{xs:20, md:20}}>理想とする労働環境を実現</AccentBox>できた！</Box></Stack>
                       
                    </Stack>
                    <Box backgroundColor="white" borderRadius={5} pt={5} pb={5}>
                        <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/TOP_effect1.webp"} loading='lazy' alt="TOP_effect1" width="100%" maxWidth={500}></Box>
                    </Box>
                </Stack>

                
            </Box>
        </Box>
    )
}

export default TopSection19;