import React from 'react';
import {Box,Stack} from '@mui/material'
import ConversionButtonA from "../../Components/ConversionButtonA";
import ConversionButtonB from "../../Components/ConversionButtonB";

function TopSection7(){

    return (
        <Box component="section" backgroundColor="#D1EAFE" textAlign="center" pt={10} pb={10}>
                <Box className='section-content' textAlign="center">
                    <Box component="h1" fontSize={{xs:30, md:45}} fontWeight="bold">助成金、<Box component="br" display={{xs:"block",md:"none"}}/><Box component="span" fontSize={{xs:20, md:30}}>どうせやるなら</Box>「フル活用」！</Box>

                    <Stack flexDirection={{sx:"column",md:"row"}} justifyContent="center" gap={10} mt={4}>
                        
                        <Stack flexBasis="30%" alignItems="center" >
                            <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/CTA1.webp"} loading='lazy' alt="CTA1" width={300} maxWidth="100%"></Box>

                            <Box width="100%"  mt={2}>
                                <Box fontSize={16} fontWeight="bold" color="#ED346C">たった<Box component="span" fontSize={30} pl={1} pr={1}>５</Box>分で<Box component="span" fontSize={30} pl={1} pr={1}>カンタン</Box>!</Box>
                                <ConversionButtonA fontSize={{xs:20, md:30}} display="block">助成金診断<Box component="span" margin="0 4px" fontSize={{xs:16, md:20}}>する</Box></ConversionButtonA>
                            </Box>
                        </Stack>
                        
                        
                        <Stack flexBasis="30%" alignItems="center" >
                            <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/CTA2.webp"} loading='lazy' alt="CTA2" width={300} maxWidth="100%"></Box>
                            <Stack flex={1} justifyContent="end" width="100%" mt={2}>
                                <ConversionButtonB fontSize={{xs:20, md:30}} display="block"><Box component="span" margin="0 4px" ></Box>プロに相談<Box component="span" margin="0 4px" fontSize={{xs:16, md:20}}>する</Box></ConversionButtonB>
                            </Stack>
                        </Stack>
                        
                    </Stack>
                </Box>
            </Box>
    )
}

export default TopSection7;