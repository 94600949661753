import React from 'react';
import {Box,Stack,Link} from '@mui/material'
import { useSearchParams } from "react-router-dom";
import ConversionButtonA from "../../Components/ConversionButtonA";
import ConversionButtonB from "../../Components/ConversionButtonB";

function Footer() {

    const [searchParams] = useSearchParams();

    let queryString = searchParams.toString()
    if(queryString?.length > 0){
        queryString = `?${queryString}`;
    }

    const style_menu={
        //textDecoration:"none",
        fontSize:15,
        color:"#393939",
    }

   

    return (
        <Box component="footer" bgcolor="#EBEBEB" p={5}>
            <Stack className='section-content' flexDirection={{sx:"column",md:"row"}} margin="0 auto" justifyContent="space-between" gap={1}>
                
                <Stack gap={2}>
                    <Link sx={style_menu} href={"/"+queryString}>
                        <img src={process.env.PUBLIC_URL+"/static/media/LP/Logo.webp"} loading='lazy' alt="logo" width={150}></img>
                    </Link>
                    <ConversionButtonA display={{xs:"none",md:"block"}} >無料で助成金診断</ConversionButtonA>
                    <ConversionButtonB display={{xs:"none",md:"block"}} >無料でプロに相談</ConversionButtonB>
                </Stack>

                <Stack flex={1} alignItems={{xs:"start",md:"end"}}>
                    <Stack flex={1} flexDirection={{xs:"column",md:"row"}} gap={{xs:2, md:10}} justifyContent={{xs:"center",md:"end"}}>
                        
                        <Stack gap={1}>
                            <Link sx={style_menu} href={"/prepare/"+queryString}>助成金に必要な準備</Link>
                            <Box sx={style_menu} >サービス内容</Box>
                            <Box ml={2}><Link sx={style_menu} href={"/service/"+queryString}>助成金コーディネートでできること</Link></Box>
                            <Box ml={2}><Link sx={style_menu} href={"/flow/"+queryString}>ご利用までの流れ</Link></Box>
                            <Box ml={2}><Link sx={style_menu} href={"/price/"+queryString}>料金</Link></Box>
                        </Stack>
                        <Stack gap={1}>
                            <Link sx={style_menu} href={"/customers/"+queryString}>導入事例</Link>
                            <Link sx={style_menu} href={"/company/"+queryString}>運営会社</Link>
                            <Link sx={style_menu} href={"/faq/"+queryString}>FAQ</Link>
                        </Stack>
                        <Stack gap={1}>
                            <Link sx={style_menu} href="https://triport.co.jp/" target='_blank'>会社情報</Link>
                            <Link sx={style_menu} href="https://triport.co.jp/privacy/" target='_blank'>プライバシーポリシー</Link>
                            <Link sx={style_menu} href={"/legal-compliance/"+queryString} target='_blank'>法令遵守について</Link>
                        </Stack>
                    </Stack>
                    <Box mt={1}>© 2024 TRIPORT Co.,Ltd. All rights reserved.</Box>
                </Stack>

                
            </Stack>
            
        </Box>
    );
}

export default Footer;