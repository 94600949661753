import React from 'react';
import {Box,Stack} from '@mui/material'
import AccentBox from '../../Components/AccentBox';

function TopSection8(){

    const before_checkMark_blue={
        '&::before':{
            content:'""', 
            display:"inline-block", 
            verticalAlign:"middle", 
            mr:"12px", 
            width:"20px", 
            height:"20px", 
            minWidth:"20px",
            background:"url("+process.env.PUBLIC_URL+"/static/media/LP/checkbox1.webp) no-repeat",backgroundSize:"contain"
        }
    }

    return (
        <Box component="section" backgroundColor="white" pt={10} pb={10}>
            <Box className='section-content' >
                <Box component="h1" textAlign="center" fontSize={{xs:20, md:30}} fontWeight="bold">あなたの会社は、<Box component="br" display={{xs:"block",md:"none"}}/><Box component="span" fontSize={{xs:30, md:45}}>助成金活用できる</Box>！？</Box>
                <Box margin="0 auto" width={80} height={2} backgroundColor="#4771DF" mt={2} mb={5}></Box>

                <Stack flexDirection={{sx:"column",md:"row"}} backgroundColor="#D1EAFE" borderRadius={5} p={2} gap={2}>
                    <Box backgroundColor="white" borderRadius={5}>
                        <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/TOP_joseikin2.webp"} loading='lazy' alt="TOP_joseikin2" style={{width:"100%",maxWidth:500}}></Box>
                    </Box>
                    <Stack justifyContent="space-around" gap={{xs:1}}>
                        <Box component="h2" fontSize={{xs:20, md:25}} fontWeight="bold"><AccentBox>雇用保険を払っている会社</AccentBox><Box component="span" fontSize={{xs:16, md:20}} ml={1}>が、対象です。</Box></Box>
                        <Stack flexDirection="row" gap={2} alignItems="center" mt={2}>
                        <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/check.webp"} loading='lazy' alt="check" width={80}></Box>
                            <Box fontSize={{xs:14, md:14}}>下記を満たしている会社であれば、助成金を活用することが可能です。</Box>
                        </Stack>
                        <Stack flexDirection="row" sx={before_checkMark_blue} alignItems="center" textAlign="left"><Box component="h3" fontSize={{xs:14, md:16}}>役員以外の従業員を<Box component="span" fontWeight="bold" fontSize={{xs:16, md:20}} pl={1} pr={1}>1名以上雇⽤</Box>している</Box></Stack>
                        <Stack flexDirection="row" sx={before_checkMark_blue} alignItems="center" textAlign="left"><Box component="h3" fontSize={{xs:14, md:16}}><Box component="span" fontWeight="bold" fontSize={{xs:16, md:20}} pr={1}>雇⽤保険・社会保険</Box>を支払っている</Box></Stack>
                        <Stack flexDirection="row" sx={before_checkMark_blue} alignItems="center" textAlign="left"><Box component="h3" fontSize={{xs:14, md:16}}>半年以内に<Box component="span" fontWeight="bold" fontSize={{xs:16, md:20}} pl={1}>会社都合の解雇をしていない</Box></Box></Stack>
                    </Stack>
                </Stack>
            </Box>
        </Box>
    )
}

export default TopSection8;