import React from 'react';
import {Box,Stack} from '@mui/material'

function TopSection11(){

 

    return (
        <Box component="section" backgroundColor="white" pt={10} pb={10}>
            <Box className='section-content' textAlign="center">
                <Box fontSize={{xs:16, md:20}} fontWeight="bold">知っておきたい</Box>
                <Box component="h1" fontSize={{xs:20, md:30}} fontWeight="bold" mt={2}>助成金の代行サービスを利用するときの<br/><Box component="span" fontSize={{xs:30, md:45}} fontWeight="bold">見極めポイント</Box></Box>
                
                <Box margin="0 auto" width={80} height={2} backgroundColor="#4771DF" mt={2} mb={10}></Box>

                <Stack flexDirection={{sx:"column",md:"row"}} justifyContent="center" gap={3}>

                    <Stack alignItems="center" flexBasis="30%" width="100%">
                        <Stack justifyContent="center" alignItems="center" mb={-6} zIndex={1} width={100} height={100} borderRadius={50} backgroundColor="#EBEBEB">
                            <Box  fontSize={50} fontWeight="bold" color="#4771DF">01</Box>
                        </Stack>
                        <Stack alignItems="center" backgroundColor="#EBEBEB" borderRadius={5} p={2} pt={6} flex={1}>
                            <Box component="h3" color="#4771DF" fontWeight="bold" mb={2}>
                                <Box fontSize={30}>フル活用</Box>
                                <Box fontSize={18}>できる</Box>
                            </Box>
                            <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/TOP_agency1.webp"} loading='lazy' alt="TOP_agency1"  width="100%" maxWidth={200}></Box>
                            <Stack flex={1} alignItems="start" mt={2} textAlign="left" width="100%">
                                <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/point.webp"} loading='lazy' alt="point"  width="100%" maxWidth={50}></Box>
                                <Stack flexDirection="row" gap={1} >
                                    <Box width={10} height={10} borderRadius={5} backgroundColor="#4771DF" mt={1}></Box>
                                    <Box flex={1} fontSize={14}>数多ある助成金の中でいろんな助成金を対応してもらえるか。</Box>
                                </Stack>
                                <Stack flexDirection="row" gap={1}>
                                    <Box width={10} height={10} borderRadius={5} backgroundColor="#4771DF" mt={1}></Box>
                                    <Box flex={1} fontSize={14}>「フル活用」できなかった…ということも。</Box>
                                </Stack>
                            </Stack>
                        </Stack>
                    </Stack>

                    <Stack alignItems="center" flexBasis="30%" width="100%">
                        <Stack justifyContent="center" alignItems="center" mb={-6} zIndex={1} width={100} height={100} borderRadius={50} backgroundColor="#EBEBEB">
                            <Box  fontSize={50} fontWeight="bold" color="#4771DF">02</Box>
                        </Stack>
                        <Stack alignItems="center" backgroundColor="#EBEBEB" borderRadius={5} p={2} pt={6} flex={1}>
                            <Box component="h3" color="#4771DF" fontWeight="bold" mb={2}>
                                <Box fontSize={30}>労務整備<Box component="span" fontSize={14}>まで</Box></Box>
                                <Box fontSize={18}>できる</Box>
                            </Box>
                            <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/TOP_agency2.webp"} loading='lazy' alt="TOP_agency2" width="100%" maxWidth={200}></Box>
                            <Stack flex={1} alignItems="start" mt={2} textAlign="left" width="100%">
                                <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/point.webp"} loading='lazy' alt="point"  width="100%" maxWidth={50}></Box>
                                <Stack flexDirection="row" gap={1} >
                                    <Box width={10} height={10} borderRadius={5} backgroundColor="#4771DF" mt={1}></Box>
                                    <Box flex={1} fontSize={14}>経営面や法令遵守まで考慮した労務整備までしてもらえるか。</Box>
                                </Stack>
                                <Stack flexDirection="row" gap={1} >
                                    <Box width={10} height={10} borderRadius={5} backgroundColor="#4771DF" mt={1}></Box>
                                    <Box flex={1} fontSize={14}>結果的に、目的の助成金が受給できなかった…、社内では使いづらいなんてことも。</Box>
                                </Stack>
                            </Stack>
                        </Stack>
                    </Stack>

                    <Stack alignItems="center" flexBasis="30%" width="100%">
                        <Stack justifyContent="center" alignItems="center" mb={-6} zIndex={1} width={100} height={100} borderRadius={50} backgroundColor="#EBEBEB">
                            <Box  fontSize={50} fontWeight="bold" color="#4771DF">03</Box>
                        </Stack>
                        <Stack alignItems="center" backgroundColor="#EBEBEB" borderRadius={5} p={2} pt={6} flex={1}>
                            <Box component="h3" color="#4771DF" fontWeight="bold" mb={2}>
                                <Box fontSize={30}>助成金<Box component="span" fontSize={14} pl={1} pr={1}>を</Box>専門<Box component="span" fontSize={14} pl={1}>とした</Box></Box>
                                <Box fontSize={18}>社労士である</Box>
                            </Box>
                            <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/TOP_agency3.webp"} loading='lazy' alt="TOP_agency3" width="100%" maxWidth={200}></Box>
                            <Stack flex={1} alignItems="start" mt={2} textAlign="left" width="100%">
                                <Box component="img"  src={process.env.PUBLIC_URL+"/static/media/LP/point.webp"} loading='lazy' alt="point" width="100%" maxWidth={50}></Box>
                                <Stack flexDirection="row" gap={1} >
                                    <Box width={10} height={10} borderRadius={5} backgroundColor="#4771DF" mt={1}></Box>
                                    <Box flex={1} fontSize={14}>助成金を専門とした社労士か。</Box>
                                </Stack>
                                <Stack flexDirection="row" gap={1} >
                                    <Box width={10} height={10} borderRadius={5} backgroundColor="#4771DF" mt={1}></Box>
                                    <Box flex={1} fontSize={14}>顧問社労士にお願いしたが、助成金は専門外のため、代行してもらえなかったということも。</Box>
                                </Stack>
                                <Box backgroundColor="white" fontSize={11} mt={2} p={1}>※助成金代行ができるのは、社労士のみ。しかし、助成金のプロであるはずの社労士であっても、助成金に精通した専門家は、社労士の中でも約１割。慎重に選びましょう。</Box>

                                <Box mt={2} textAlign="left" fontSize={11}>*社労士の中でも「あらゆる助成金をフル活用できる社労士」の割合</Box>
                            </Stack>
                        </Stack>
                    </Stack>

                </Stack>
            </Box>
        </Box>
    )
}

export default TopSection11;