import React from 'react';
import {Box,Stack} from '@mui/material'

function TopSection16(){

    const before_checkMark_blue={
        '&::before':{
            content:'""', 
            display:"inline-block", 
            verticalAlign:"middle", 
            mr:"12px", 
            width:"20px", 
            height:"20px", 
            minWidth:"20px",
            background:"url("+process.env.PUBLIC_URL+"/static/media/LP/checkbox1.webp) no-repeat",backgroundSize:"contain"
        }
    }

    return (
        <Box component="section" backgroundColor="white" pt={10} pb={10}>
                <Box className='section-content' textAlign="center">
                    <Box fontSize={{xs:16, md:20}} fontWeight="bold">助成金コーディネートで</Box>
                    <Box component="h1" fontSize={{xs:25, md:45}} fontWeight="bold" mt={5}>
                        <Box >人材リソース<Box component="span" fontSize={{xs:18, md:30}} ml={1} mr={1}>を</Box>最大限活用<Box component="span" fontSize={{xs:18, md:30}} ml={1} mr={1}>し、</Box></Box>
                        <Box >生産性の高い会社<Box component="span" fontSize={{xs:18, md:30}} ml={1}>づくりをしませんか？</Box></Box>
                    </Box>
                    <Box margin="0 auto" width={80} height={2} backgroundColor="#4771DF" mt={2} mb={5}></Box>

                    <Stack fontSize={30} flexDirection={{sx:"column",md:"row"}} justifyContent="space-evenly" mt={4} gap={1}>
                        <Stack flexBasis="30%">
                            <Stack flexDirection="row" backgroundColor="#D1EAFE"  borderRadius={5} alignItems="center">
                                <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/TOP_labor1.webp"} loading='lazy' alt="TOP_labor1" width="100%" maxWidth={200}></Box>
                                <Box component="h3" flex={1} fontSize={20} fontWeight="bold">採用</Box>
                            </Stack>
                            
                            <Stack flexDirection="row" sx={before_checkMark_blue} p={2} textAlign="left"><Box flex={1} fontSize={14}><Box component="span" fontWeight="bold">働きぶりや人当たり</Box>を見て、<br></br><Box component="span" fontSize={{xs:16, md:18}} fontWeight="bold">ミスマッチのない採用</Box>ができる</Box></Stack>
                            
                        </Stack>

                        <Stack flexBasis="30%">
                            <Stack flexDirection="row" backgroundColor="#D1EAFE" borderRadius={5} alignItems="center">
                                <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/TOP_labor2.webp"} loading='lazy' alt="TOP_labor2" width="100%" maxWidth={200}></Box>
                                <Box component="h3" flex={1} fontSize={20} fontWeight="bold">研修</Box>
                            </Stack>
                            
                            <Stack flexDirection="row" sx={before_checkMark_blue} p={2} textAlign="left"><Box flex={1} fontSize={14}><Box component="span" fontSize={{xs:16, md:18}} fontWeight="bold">社員が自発的にスキルアップ</Box><br></br><Box component="span" fontWeight="bold">できる環境</Box>をつくれる</Box></Stack>
                        </Stack>

                        <Stack flexBasis="30%">
                            <Stack flexDirection="row" backgroundColor="#D1EAFE" borderRadius={5} alignItems="center">
                                <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/TOP_labor3.webp"} loading='lazy' alt="TOP_labor3" width="100%" maxWidth={200}></Box>
                                <Box component="h3" flex={1} fontSize={20} fontWeight="bold">設備投資</Box>
                            </Stack>
                            
                            <Stack flexDirection="row" sx={before_checkMark_blue} p={2} textAlign="left"><Box flex={1} fontSize={14}><Box component="span" fontSize={{xs:16, md:18}} fontWeight="bold">生産性を上げる設備</Box>を導入し、<br></br><Box component="span" fontWeight="bold">貴重な人材リソースを最大限活用</Box>できる</Box></Stack>
                        </Stack>
                        
                    </Stack>

                    <Stack fontSize={30} flexDirection={{sx:"column",md:"row"}} justifyContent="space-evenly" mt={4} gap={1}>
                        <Stack flexBasis="30%">
                            <Stack flexDirection="row" backgroundColor="#D1EAFE"  borderRadius={5} alignItems="center">
                                <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/TOP_labor4.webp"} loading='lazy' alt="TOP_labor4" width="100%" maxWidth={200}></Box>
                                <Box component="h3" flex={1} fontSize={20} fontWeight="bold">待遇改善</Box>
                            </Stack>
                            <Stack flexDirection="row" sx={before_checkMark_blue} p={2} textAlign="left"><Box flex={1} fontSize={14}><Box component="span" fontWeight="bold">パート・アルバイトが正社員が同じ</Box><br></br><Box component="span" fontSize={{xs:16, md:18}} fontWeight="bold">目標に向かえる環境</Box>をつくれる</Box></Stack>
                        </Stack>

                        <Stack flexBasis="30%">
                            <Stack flexDirection="row" backgroundColor="#D1EAFE" borderRadius={5} alignItems="center">
                            <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/TOP_labor5.webp"} loading='lazy' alt="TOP_labor5" width="100%" maxWidth={200}></Box>
                                <Box component="h3" flex={1} fontSize={20} fontWeight="bold">育児・介護</Box>
                            </Stack>
                            <Stack flexDirection="row" sx={before_checkMark_blue} p={2} textAlign="left"><Box flex={1} fontSize={14}><Box component="span" fontWeight="bold">家庭環境や従業員の</Box><Box component="span" fontSize={{xs:16, md:18}} fontWeight="bold">ライフステージ<br></br>の変化にも対応</Box>できる</Box></Stack>
                        </Stack>

                    </Stack>
                    
                </Box>
            </Box>
    )
}

export default TopSection16;