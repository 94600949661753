import './App.css';
import { lazy} from 'react';
import { BrowserRouter,Routes,Route } from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async'

import Top from "./Pages/LP/Top"
const Faq = lazy(()=>import('./Pages/LP/Faq'));
const Flow = lazy(()=>import('./Pages/LP/Flow'));
const Price = lazy(()=>import('./Pages/LP/Price'));
const Prepare = lazy(()=>import('./Pages/LP/Prepare'));
const Service = lazy(()=>import('./Pages/LP/Service'));
const Company = lazy(()=>import('./Pages/LP/Company'));
const Customers = lazy(()=>import('./Pages/Customers/Customers'));

const LeagalCompliance = lazy(()=>import('./Pages/LP/LegalCompliance.js'));
const Customer1 = lazy(()=>import("./Pages/Customers/Customer1.js"));
const Customer2 = lazy(()=>import("./Pages/Customers/Customer2.js"));
const Customer3 = lazy(()=>import("./Pages/Customers/Customer3.js"));
const Customer4 = lazy(()=>import("./Pages/Customers/Customer4.js"));
const Customer5 = lazy(()=>import("./Pages/Customers/Customer5"));
const Customer6 = lazy(()=>import("./Pages/Customers/Customer6"));

function App() {

 

  return (
    <div className="App">
      {/* <Suspense fallback={<>Loading</>}> */}
      <HelmetProvider>
        <BrowserRouter>
          <Routes>
            <Route path={"/legal-compliance"} element={<>
              <Helmet><title>法令遵守について｜TRIPORT</title></Helmet>
              <LeagalCompliance/>
            </>}/>
            <Route path={"/"} element={<>
              <Helmet><title>助成金の代行・申請ならTRIPORTの助成金コーディネート</title></Helmet>
              <Top/>
            </>}/>

            <Route path={"/prepare"} element={<>
              <Helmet><title>助成金申請に必要な準備｜TRIPORT</title></Helmet>
              <Prepare/>
            </>}/>

            <Route path={"/faq"} element={<>
              <Helmet><title>よくあるご質問(FAQ)｜TRIPORT</title></Helmet>
              <Faq/>
            </>}/>

            <Route path={"/flow"} element={<>
              <Helmet><title>サービスご利用の流れ｜TRIPORT</title></Helmet>
              <Flow/>
            </>}/>

            <Route path={"/price"} element={<>
              <Helmet><title>サービス料金｜TRIPORT</title></Helmet>
              <Price/>
            </>}/>

            <Route path={"/service"} element={<>
              <Helmet><title>助成金コーディネートサービス概要｜TRIPORT</title></Helmet>
              <Service/>
            </>}/>

            <Route path={"/company"} element={<>
              <Helmet><title>会社概要｜TRIPORT</title></Helmet>
              <Company/>
            </>}/>

            <Route path={"/customers"} element={<>
              <Helmet><title>助成金活用のお客様事例｜TRIPORT</title></Helmet>
              <Customers/>
            </>}/>
            
            <Route path={"/customers/nicoichi"} element={<>
              <Helmet><title>助成金活用事例-株式会社にこいち様｜TRIPORT</title></Helmet>
              <Customer1/>
            </>}/>

            <Route path={"/customers/criacao"} element={<>
              <Helmet><title>キャリアアップ助成金・両立支援等助成金活用事例-株式会社Criacao様｜TRIPORT</title></Helmet>
              <Customer2/>
            </>}/>

            <Route path={"/customers/overcome-music"} element={<>
              <Helmet><title>助成金活用事例-株式会社OVERCOME MUSIC様｜TRIPORT</title></Helmet>
              <Customer3/>
            </>}/>

            <Route path={"/customers/kamiyasou"} element={<>
              <Helmet><title>業務改善助成金活用事例-神谷荘様｜TRIPORT</title></Helmet>
              <Customer4/>
            </>}/>

            <Route path={"/customers/freestyle"} element={<>
              <Helmet><title>キャリアアップ助成金活用事例-FREEStyle様｜TRIPORT</title></Helmet>
              <Customer5/>
            </>}/>

            <Route path={"/customers/aptamuse"} element={<>
              <Helmet><title>キャリアアップ助成金・両立支援等助成金活用事例-アプトアミューズ様｜TRIPORT</title></Helmet>
              <Customer6/>
            </>}/>

            
            
          </Routes>
        </BrowserRouter>
      </HelmetProvider>
      {/* </Suspense> */}
    </div>
  );
}

export default App;
