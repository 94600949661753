import React from 'react';
import {Box,Stack} from '@mui/material'

function TopSection18(){

    const before_checkMark_blue={
        '&::before':{
            content:'""', 
            display:"inline-block", 
            verticalAlign:"middle", 
            mr:"12px", 
            width:"20px", 
            height:"20px", 
            minWidth:"20px",
            background:"url("+process.env.PUBLIC_URL+"/static/media/LP/checkbox1.webp) no-repeat",backgroundSize:"contain"
        }
    }

    return (
        <Box component="section" backgroundColor="white" pt={10} pb={10}>
            <Box className='section-content' >
                <Box component="h1" textAlign="center" fontSize={{xs:20, md:30}} fontWeight="bold">助成金コーディネートが<Box component="br" display={{xs:"block",md:"none"}}/><Box component="span" fontSize={{xs:30, md:45}} ml={1}>選ばれる6つの理由</Box></Box>
                <Box margin="0 auto" width={80} height={2} backgroundColor="#4771DF" mt={2} mb={5}></Box>

                <Stack flexDirection={{sx:"column",md:"row"}} backgroundColor="#D1EAFE" borderRadius={5} p={2} gap={2}>
                    <Box backgroundColor="white" borderRadius={5}>
                        <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/TOP_reason1.webp"} loading='lazy' alt="TOP_reason1" width="100%" maxWidth={500}></Box>
                    </Box>
                    <Stack flex={1} justifyContent="start" gap={2}>
                        <Stack component="h3" flexDirection="row" fontSize={{xs:18, md:25}} fontWeight="bold" alignItems="center" gap={1}><Box color="#4771DF" fontSize={60}>01</Box>労務整備＆メンテナンスがしっかりできる</Stack>
                        <Stack flexDirection="row" sx={before_checkMark_blue} textAlign="left"><Box fontSize={{xs:14, md:14}}>就業規則、賃金規程、育児休業規程、雇用契約書、36協定、その他労使協定、賃金台帳、出勤簿、労働者名簿などの帳簿類について、各帳簿間の「整合」も取り、しっかり整備します。
法律が変わった場合の対応までできるので、その後のメンテナンスも安心。</Box></Stack>
                    </Stack>
                </Stack>

                <Stack flexDirection={{sx:"column",md:"row"}} backgroundColor="#D1EAFE" borderRadius={5} p={2} gap={2} mt={5}>
                    <Box backgroundColor="white" borderRadius={5}>
                        <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/TOP_reason3.webp"} loading='lazy' alt="TOP_reason2" width="100%" maxWidth={500}></Box>
                    </Box>
                    <Stack flex={1} justifyContent="start" gap={2}>
                        <Stack component="h3" flexDirection="row" fontSize={{xs:18, md:25}} fontWeight="bold" alignItems="center" gap={1}><Box color="#4771DF" fontSize={60}>02</Box>貴社専属のプロ集団で安心のサポート体制</Stack>
                        <Stack flexDirection="row" sx={before_checkMark_blue} textAlign="left"><Box fontSize={{xs:14, md:14}}>貴社専属のチームを構成し、各分野の専門家がサポート。
各分野の専門家がチームを組んで担当するため、相談内容に応じて最適解をご用意できます。</Box></Stack>
                    </Stack>
                </Stack>

                <Stack flexDirection={{sx:"column",md:"row"}} backgroundColor="#D1EAFE" borderRadius={5} p={2} gap={2} mt={5}>
                    <Box backgroundColor="white" borderRadius={5}>
                        <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/TOP_reason2.webp"} loading='lazy' alt="TOP_reason3" width="100%" maxWidth={500}></Box>
                    </Box>
                    <Stack flex={1} justifyContent="start" gap={2}>
                        <Stack component="h3" flexDirection="row" fontSize={{xs:18, md:25}} fontWeight="bold" alignItems="center" gap={1}><Box color="#4771DF" fontSize={60}>03</Box>複数の視点で帳簿をチェック</Stack>
                        <Stack flexDirection="row" sx={before_checkMark_blue} textAlign="left"><Box fontSize={{xs:14, md:14}}>「法令遵守」「貴社独自の経営方針」「助成金の受給要件」といった、複数の視点で帳簿をチェックし整備します。
これにより、自社独自の労務環境を構築できるだけでなく、継続的に助成金を活用するサイクルをつくることができます。</Box></Stack>
                    </Stack>
                </Stack>

                <Stack flexDirection={{sx:"column",md:"row"}} backgroundColor="#D1EAFE" borderRadius={5} p={2} gap={2} mt={5}>
                    <Box backgroundColor="white" borderRadius={5}>
                        <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/TOP_reason4.webp"} loading='lazy' alt="TOP_reason4" width="100%" maxWidth={500}></Box>
                    </Box>
                    <Stack flex={1} justifyContent="start" gap={2}>
                        <Stack component="h3" flexDirection="row" fontSize={{xs:18, md:25}} fontWeight="bold" alignItems="center" gap={1}><Box color="#4771DF" fontSize={60}>04</Box>進捗もWEBでしっかり管理</Stack>
                        <Stack flexDirection="row" sx={before_checkMark_blue} textAlign="left"><Box fontSize={{xs:14, md:14}}>マイページにて、いつでも進捗状況が把握できます。
進捗状況が確認できるので、不安なく手続きを進めることができます。</Box></Stack>
                    </Stack>
                </Stack>

                <Stack flexDirection={{sx:"column",md:"row"}} backgroundColor="#D1EAFE" borderRadius={5} p={2} gap={2} mt={5}>
                    <Box backgroundColor="white" borderRadius={5}>
                        <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/TOP_reason1.webp"} loading='lazy' alt="TOP_reason1" width="100%" maxWidth={500}></Box>
                    </Box>
                    <Stack flex={1} justifyContent="start" gap={2}>
                        <Stack component="h3" flexDirection="row" fontSize={{xs:18, md:25}} fontWeight="bold" alignItems="center" gap={1}><Box color="#4771DF" fontSize={60}>05</Box>厚労省等に認められた<br></br>高付加価値で低料金のサービス</Stack>
                        <Stack flexDirection="row" sx={before_checkMark_blue} textAlign="left"><Box fontSize={{xs:14, md:14}}>厚生労働省等後援の「日本HRチャレンジ大賞」奨励賞を受賞※。
「ITのチカラによって、全国の中小企業に対して高付加価値なサービスを低料金で安定的に提供」している点が高く評価されました。
※日本HRチャレンジ大賞は、「クラウド社労士コモン」サービスにて受賞</Box></Stack>
                    </Stack>
                </Stack>

                <Stack flexDirection={{sx:"column",md:"row"}} backgroundColor="#D1EAFE" borderRadius={5} p={2} gap={2} mt={5}>
                    <Box backgroundColor="white" borderRadius={5}>
                        <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/TOP_reason6.webp"} loading='lazy' alt="TOP_reason6" width="100%" maxWidth={500}></Box>
                    </Box>
                    <Stack flex={1} justifyContent="start" gap={2}>
                        <Stack component="h3" flexDirection="row" fontSize={{xs:18, md:25}} fontWeight="bold" alignItems="center" gap={1}><Box color="#4771DF" fontSize={60}>06</Box>チャットで気軽に。やり取り簡単！</Stack>
                        <Stack flexDirection="row" sx={before_checkMark_blue} textAlign="left"><Box fontSize={{xs:14, md:14}}>チャットで気軽にやり取りできるサービスです。
必要に応じて、電話やテレビ電話も可能です。気軽なだけでなく、お客様の疑問点を解消しやすいサービス提供を心がけています。</Box></Stack>
                    </Stack>
                </Stack>
            </Box>
        </Box>
    )
}

export default TopSection18;