import React from 'react';
import {Box,Stack} from '@mui/material'

function TopSection12(){

    return (
        <Box component="section" backgroundColor="#4771DF" textAlign="center" color="white" pt={10} >
                <Box className='section-content' textAlign="center">

                    <Stack fontSize={30} flexDirection="row" justifyContent="space-evenly" mb={5}>
                        <Box>
                            <Box component="h2" fontSize={{xs:16, md:23}} backgroundColor="white" color="#4771DF" p={2} borderRadius={5} textAlign="left" fontWeight="bold">
                                <Box>その課題、</Box>
                                <Box><Box component="span" fontSize={{xs:20, md:35}}>「助成金コーディネート」</Box>で解決しませんか？！</Box>
                            </Box>
                            <Stack justifyContent="center" alignItems="center" position="relative" >
                                <Box width="30px" height="calc(60px / 2)" sx={{clipPath:"polygon(0 0, 100% 0, 50% 100%)"}} backgroundColor="white" position="absolute" bottom={-20}></Box>
                            </Stack>
                        </Box>
                    </Stack>

                    <Box component="h1" fontSize={{xs:20, md:30}} fontWeight="bold" mb={5}>助成金を<br/><Box component="span" fontSize={{xs:30, md:45}}>フル活用できるノウハウ</Box><br/>がここにある！</Box>
                    <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/TOP_bulb.webp"} loading='lazy' alt="TOP_bulb" width="100%" maxWidth={75}></Box>

                    
                </Box>

            </Box>
    )
}

export default TopSection12;