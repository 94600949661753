import React from 'react';
import {Box,Stack} from '@mui/material'

function TopSection15(){

    return (
        <Box component="section" backgroundColor="#EBEBEB" pt={10} pb={10}>
                <Box className='section-content' textAlign="center">
                    <Box component="h1" fontSize={{xs:20, md:45}} fontWeight="bold">助成金コーディネート<Box component="span" fontSize={{xs:16, md:30}} ml={1}>とは？</Box></Box>
                    <Box margin="0 auto" width={80} height={2} backgroundColor="#4771DF" mt={2} mb={2}></Box>

                    <Box component="h2" fontSize={{xs:14, md:20}} >
                        <Box>労務・助成金に精通したプロ集団が、会社の労務整備から助成金を受給するまでをしっかりサポート。</Box>
                        <Box>助成金をフル活用し続けられる経営サイクルを実現するため、労務のしくみづくりからお手伝いします。</Box>
                    </Box>

                    <Stack fontSize={30} flexDirection={{sx:"column",md:"row"}} justifyContent="space-evenly" mt={4} gap={1}>

                        <Stack flexBasis="30%">
                            <Box fontSize={60} color="#93B1FF">01</Box>
                            <Stack flex={1} borderRadius={5} backgroundColor="white">
                                <Box component="h3" backgroundColor="#4771DF" color="white" borderRadius="15px 15px 0 0" p={2} fontSize={25} fontWeight="bold"><Box component="span" fontSize={16} mr={1}>助成金</Box>「フル活用」<Box component="span" fontSize={16} ml={1} mr={1}>の<br></br></Box>サイクル<Box component="span" fontSize={16} mr={1}>を実現</Box></Box>

                                <img src={process.env.PUBLIC_URL+"/static/media/LP/TOP_Feature1.webp"} loading='lazy' alt="TOP_Feature1" style={{width:"100%"}}></img>
                                    
                                <Stack flexDirection="row" gap={1} flex={1} p={2} fontSize={14}  textAlign="left">
                                    
                                        <Box width={10} height={10} borderRadius={5} backgroundColor="#4771DF" mt={1}></Box>
                                        <Box flex={1}>助成金活用をし続けられるような労務のしくみづくりからしっかりサポートします。</Box>
                                    
                                </Stack>
                            </Stack>
                            
                        </Stack>

                        <Stack flexBasis="30%">
                            <Box fontSize={60} color="#93B1FF">02</Box>
                            <Stack flex={1} borderRadius={5} backgroundColor="white">
                                <Box component="h3" backgroundColor="#4771DF" color="white" borderRadius="15px 15px 0 0" p={2} fontSize={25} fontWeight="bold"><Box component="span" fontSize={16} mr={1}>助成金の受給要件＋法令遵守／経営方針<br></br>までしっかり</Box>労務整備</Box>

                                <img src={process.env.PUBLIC_URL+"/static/media/LP/TOP_Feature2.webp"} loading='lazy' alt="TOP_Feature2" style={{width:"100%"}}></img>
                                    
                                <Stack flexDirection="row" gap={1} flex={1} p={2} fontSize={14} textAlign="left">
                                    
                                        <Box width={10} height={10} borderRadius={5} backgroundColor="#4771DF" mt={1}></Box>
                                        <Box flex={1}>助成金の受給要件のみならず、法令遵守や貴社独自の経営方針も加味した帳簿類の作成・整備・維持管理まで、しっかりお手伝いします。</Box>
                                    
                                </Stack>
                            </Stack>
                            
                        </Stack>

                        <Stack flexBasis="30%">
                            <Box fontSize={60} color="#93B1FF">03</Box>
                            <Stack flex={1} borderRadius={5} backgroundColor="white">
                                <Box component="h3" backgroundColor="#4771DF" color="white" borderRadius="15px 15px 0 0" p={2} fontSize={25} fontWeight="bold"><Box component="span" fontSize={16} mr={1}>労務・助成金の</Box>プロ集団<Box component="span" fontSize={16} ml={1} mr={1}>が<br></br></Box>チーム<Box component="span" fontSize={16} ml={1}>を組んで、しっかりサポート</Box></Box>

                                <img src={process.env.PUBLIC_URL+"/static/media/LP/TOP_Feature3.webp"} loading='lazy' alt="TOP_Feature3" style={{width:"100%"}}></img>

                                
                                <Stack flexDirection="row" gap={1} flex={1} p={2} fontSize={14} textAlign="left">
                                    
                                        <Box width={10} height={10} borderRadius={5} backgroundColor="#4771DF" mt={1}></Box>
                                        <Box flex={1}>助成金診断実績 20,000社以上。労務・助成金に精通したプロ集団がしっかりサポートします。</Box>
                                    
                                </Stack>
                                
                            </Stack>
                            
                        </Stack>
                        
                    </Stack>
                    
                </Box>
            </Box>
    )
}

export default TopSection15;