import React,{ useState } from 'react';
import {Box,Stack,Link, Divider,Menu} from '@mui/material'
import { useSearchParams,useLocation } from "react-router-dom";
import MenuIcon from '@mui/icons-material/Menu';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';
import ConversionButtonA from "../../Components/ConversionButtonA";
import ConversionButtonB from "../../Components/ConversionButtonB";



function Header() {

    const [searchParams] = useSearchParams();
    const pathname = useLocation().pathname;

    let queryString = searchParams.toString()
    if(queryString?.length > 0){
        queryString = `?${queryString}`;
    }

    const style_menu={
        textDecoration:"none",
        fontWeight:"bold",
        color:"#393939"
    }

    const active_menu={
        color:"#4771DF",
        "&::before":{
            width:"100%"
        }
    }

    const style_menu2={
        textDecoration:"none",
        fontWeight:"bold",
        color:"#393939",
        width:"100%",
        padding:"20px 20px",
        
        
    }

    const [anchorEl, setAnchorEl] = useState(null);
    const openSubMenu = (event)=>{
        setAnchorEl(event.currentTarget)
    }
    
    const closeSubMenu = () => {
        setAnchorEl(null);
    };


    const [isOpen,setIsOpen] = useState(false);

    return (
        <Box component="header" position="sticky" left={0} right={0} top={0} backgroundColor="rgba(255, 255, 255, 0.9)" zIndex={999} boxShadow="1px 1px 2px rgba(0,0,0,0.3)">
            <Stack className='section-content' flexDirection="row" margin="0 auto" justifyContent="space-between" alignItems="center" gap={1} p={1}>
                <Link sx={style_menu} href={"/"+queryString}>
                    <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/Logo.webp"} loading='lazy' alt="logo" width={170} mr={5}></Box>
                </Link>
                
                
                <Stack component="nav" display={{xs:"none",md:"flex"}} flex={1} flexDirection="row" gap={3} justifyContent="start" >
                    <Link sx={style_menu} href={"/"+queryString}>
                        <Box component="span" sx={pathname==="/" ? active_menu:{}}>TOP</Box>
                    </Link>
                    <Link sx={style_menu} href={"/prepare/"+queryString}>
                        <Box component="span" sx={pathname==="/prepare/" ? active_menu:{}}>助成金に必要な準備</Box>
                    </Link>
                    
                    <Link sx={style_menu} onClick={(event)=>{openSubMenu(event)}}>
                        <Stack component="span" direction="row" alignItems="center">
                            <Box display="inline">サービス内容</Box>
                            <ExpandMoreIcon/>
                        </Stack>
                    </Link>
                    
                    <Link sx={style_menu} href={"/customers/"+queryString}>
                        <Box component="span" sx={pathname.indexOf("/customers")>=0 ? active_menu:{}}>導入事例</Box>
                    </Link>
                    <Link sx={style_menu} href={"/company/"+queryString}>
                        <Box component="span" sx={pathname==="/company/" ? active_menu:{}}>運営会社</Box>
                    </Link>
                    <Link sx={style_menu} href={"/faq/"+queryString}>
                        <Box component="span" sx={pathname==="/faq/" ? active_menu:{}}>FAQ</Box>
                    </Link>
                </Stack>

                <Stack display={{xs:"none",md:"flex"}} flexDirection="row" flexWrap="wrap" gap={1}>
                    <ConversionButtonA>無料で助成金診断</ConversionButtonA>
                    <ConversionButtonB>無料でプロに相談</ConversionButtonB>
                </Stack>

                <Box display={{xs:"flex",md:"none"}} onClick={()=>{setIsOpen(!isOpen)}}>
                    {isOpen===false ? <MenuIcon/>:<CloseIcon/>}
                </Box>
            </Stack>

            <Menu
                component="nav"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={closeSubMenu}
                onClick={closeSubMenu}
                PaperProps={{
                    
                    sx: {
                        p:2
                    },
                    }}
                transformOrigin={{ horizontal: 'center', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
            >
                <Box>
                    <Link sx={style_menu} href={"/service/"+queryString} onClick={()=>{closeSubMenu();}}>
                        <Stack flexDirection="row" gap={2}>
                            <Box flex={1} sx={pathname==="/service/" ? active_menu:{}}>助成金コーディネートでできること</Box>
                            <ArrowForwardIcon/>
                        </Stack>
                    </Link>
                </Box>
                <Box mt={2}>
                    <Link sx={style_menu} href={"/flow/"+queryString} onClick={()=>{closeSubMenu();}}>
                        <Stack flexDirection="row" gap={2}>
                            <Box flex={1} sx={pathname==="/flow/" ? active_menu:{}}>ご利用までの流れ</Box>
                            <ArrowForwardIcon/>
                        </Stack>
                    </Link>
                </Box>
                <Box mt={2}>
                    <Link sx={style_menu} href={"/price/"+queryString} onClick={()=>{closeSubMenu();}}>
                        <Stack flexDirection="row" gap={2}>
                            <Box flex={1} sx={pathname==="/price/" ? active_menu:{}}>料金</Box>
                            <ArrowForwardIcon/>
                        </Stack>
                    </Link>
                </Box>
                
            </Menu>

            {isOpen &&
            <Box height="100vh" position="fixed" zIndex={-1} top={0} bottom={0} left={0} right={0} backgroundColor="white" overflow="auto">
                <Stack flexDirection="column" mt="70px" mb="100px" alignItems="center" >
                    <Divider flexItem/>
                    <Link sx={style_menu2} href={"/"+queryString}>
                        <Stack flexDirection="row" >
                            <Box flex={1} sx={pathname==="/" ? active_menu:{}}>TOP</Box>
                            <ArrowForwardIcon/>
                        </Stack>
                    </Link>
                    <Divider flexItem/>
                    <Link sx={style_menu2} href={"/prepare/"+queryString}>
                        <Stack flexDirection="row" >
                            <Box flex={1} sx={pathname==="/prepare/" ? active_menu:{}}>助成金に必要な準備</Box>
                            <ArrowForwardIcon/>
                        </Stack>
                    </Link>
                    <Divider flexItem/>
                    <Box sx={style_menu2} >サービス内容
                    </Box>
                    <Divider flexItem/>
                    <Link sx={style_menu2} href={"/service/"+queryString}>
                        <Stack flexDirection="row" ml={2}>
                            <Box flex={1} sx={pathname==="/service/" ? active_menu:{}}>助成金コーディネートでできること</Box>
                            <ArrowForwardIcon/>
                        </Stack>
                    </Link>
                    <Divider flexItem/>
                    <Link sx={style_menu2} href={"/flow/"+queryString}>
                        <Stack flexDirection="row" ml={2}>
                            <Box flex={1} sx={pathname==="/flow/" ? active_menu:{}}>ご利用までの流れ</Box>
                            <ArrowForwardIcon/>
                        </Stack>
                    </Link>
                    <Divider flexItem/>
                    <Link sx={style_menu2} href={"/price/"+queryString}>
                        <Stack flexDirection="row" ml={2}>
                            <Box flex={1} sx={pathname==="/price/" ? active_menu:{}}>料金</Box>
                            <ArrowForwardIcon/>
                        </Stack>
                    </Link>
                    <Divider flexItem/>
                    <Link sx={style_menu2} href={"/customers/"+queryString}>
                        <Stack flexDirection="row" >
                            <Box flex={1} sx={pathname.indexOf("/customers/")>=0 ? active_menu:{}}>導入事例</Box>
                            <ArrowForwardIcon/>
                        </Stack>
                    </Link>
                    <Divider flexItem/>
                    <Link sx={style_menu2} href={"/company/"+queryString}>
                        <Stack flexDirection="row" >
                            <Box flex={1} sx={pathname==="/company/" ? active_menu:{}}>運営会社</Box>
                            <ArrowForwardIcon/>
                        </Stack>
                    </Link>
                    <Divider flexItem/>
                    <Link sx={style_menu2} href={"/faq/"+queryString}>
                        <Stack flexDirection="row" >
                            <Box flex={1} sx={pathname==="/faq/" ? active_menu:{}}>FAQ</Box>
                            <ArrowForwardIcon/>
                        </Stack>
                    </Link>
                    <Divider flexItem/>
                </Stack>
                </Box>
            }
            
        </Box>
        
    );
}

export default Header;