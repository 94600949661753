import React from 'react';
import {Box,Stack} from '@mui/material'
import AccentBox from '../../Components/AccentBox';

function TopSection14(){

    return (
        <Box component="section" backgroundColor="white" pb={10}>
            <Box className='section-content' textAlign="center">
                {/* <Stack justifyContent="center" alignItems="center" position="relative" pb={20}>
                    <Box width="30%" height="calc(200px / 2)" sx={{clipPath:"polygon(0 0, 100% 0, 50% 100%)"}} backgroundColor="#D1EAFE" position="absolute" top={-5}></Box>
                </Stack> */}
                <Box margin="0 auto" width="30%" height={{xs:"50px",md:"100px"}} sx={{clipPath:"polygon(0 0, 100% 0, 50% 100%)",transform:"translateY(-1px)"}} backgroundColor="#D1EAFE" ></Box>

                <Stack fontSize={30} mt={5} flexDirection={{sx:"column",md:"row"}} justifyContent="space-evenly" alignItems="center" gap={3}>
                    <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/TOP_award1.webp"} loading='lazy' alt="TOP_award1" width={250} maxWidth="100%"></Box>
                    <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/TOP_award2.webp"} loading='lazy' alt="TOP_award2" width={250} maxWidth="100%"></Box>
                    <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/TOP_award3.webp"} loading='lazy' alt="TOP_award3" width={250} maxWidth="100%"></Box>
                </Stack>
                <Box fontSize={11} mt={6} textAlign="left">
                    <Box>※1 2024年3月現在</Box>
                    <Box>※2 日本HRチャレンジ大賞は、助成金コーディネートの元となるサービスである「クラウド社労士コモン」が受賞しました。</Box>
                    <Box>※3 厚生労働省『輝くテレワーク賞』特別奨励賞に受賞、総務省『テレワーク先駆者百選』に選出、東京都『東京ライフワークバランス認定企業』に認定、東京都『東京都女性活躍推進大賞』優秀賞を受賞、など</Box>
                </Box>

                <Box component="h1" fontWeight="bold" fontSize={{xs:30, md:40}} mt={10}>
                    <Box>助成金<Box component="span" fontSize={{xs:20, md:25}} ml={1} mr={1}>を</Box>フル活用<Box component="span" fontSize={{xs:20, md:25}} ml={1} mr={1}>して</Box></Box>
                    <Box>社員が輝く経営サイクル<Box component="span" fontSize={{xs:20, md:25}} ml={1}>をつくる</Box></Box>
                </Box>

                <Box fontWeight="bold" fontSize={{xs:35, md:65}} mt={5} mb={5}>助成金コーディネート</Box>

                <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/TOP_J_coordinate.webp"} loading='lazy' alt="TOP_J_coordinate" width="100%" maxWidth={500}></Box>

                <Box fontSize={{xs:20, md:30}} fontWeight="bold" mt={10}>
                    <Box><AccentBox>多数実績</AccentBox><Box component="span" fontSize={{xs:16, md:20}} ml={1} mr={1}>の中で培ってきた</Box><Box component="br" display={{xs:"block",md:"none"}}></Box><AccentBox>ノウハウ・ナレッジ。</AccentBox></Box>
                    <Box><Box component="span" fontSize={{xs:16, md:20}} ml={1} mr={1}>労務・助成金の</Box><AccentBox>プロ集団</AccentBox><Box component="span" fontSize={{xs:16, md:20}} ml={1} mr={1}>が、継続的に助成金をフル活用できる</Box><AccentBox>しくみづくり</AccentBox><Box component="span" fontSize={{xs:16, md:20}} ml={1} >をサポートします。</Box></Box>
                </Box>
                
            </Box>
        </Box>
    )
}

export default TopSection14;