import React from 'react';
import {Box,Stack} from '@mui/material'

function TopSection4(){

    return (
        <Box component="section" backgroundColor="#F2F9FF" pt={10} pb={10}>
                <Box className='section-content' textAlign="center">
                    <Box component="h1" fontSize={{xs:20, md:30}} fontWeight="bold">助成金を活用して<Box component="br" display={{xs:"block",md:"none"}}/><Box component="span" ml={1} mr={1} fontSize={{xs:24, md:45}}>目指す</Box>のは、こんな会社づくり！</Box>
                    <Box margin="0 auto" width={80} height={2} backgroundColor="#4771DF" mt={2} mb={5}></Box>

                    <Stack fontSize={30} flexDirection={{sx:"column",md:"row"}} justifyContent="center" mt={4} gap={3} color="white">
                        <Box flexBasis="30%">
                            <Stack flexDirection="row" backgroundColor="#4771DF" p={2} borderRadius={5} alignItems="center">
                                <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/TOP_icon1.webp"} loading='lazy' alt="TOP_icon1" style={{width:50}}></Box>
                                <Box component="h3" flex={1} pr="50px">
                                    <Box fontSize={20}>ミスマッチのない</Box>
                                    <Box fontSize={25}>採用</Box>
                                </Box>
                            </Stack>
                            <Stack justifyContent="center" alignItems="center" position="relative" >
                                <Box width="30px" height="calc(60px / 2)" sx={{clipPath:"polygon(0 0, 100% 0, 50% 100%)"}} backgroundColor="#4771DF" position="absolute" bottom={-20}></Box>
                            </Stack>
                            
                        </Box>
                        <Box flexBasis="30%">
                            <Stack flexDirection="row" backgroundColor="#4771DF" p={2} borderRadius={5} alignItems="center">
                                <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/TOP_icon2.webp"} loading='lazy' alt="TOP_icon2" style={{width:50}}></Box>
                                <Box component="h3" flex={1} pr="50px">
                                    <Box fontSize={20}>社員が自発的に</Box>
                                    <Box fontSize={25}>スキルアップ</Box>
                                </Box>
                            </Stack>
                            <Stack justifyContent="center" alignItems="center" position="relative" >
                                <Box width="30px" height="calc(60px / 2)" sx={{clipPath:"polygon(0 0, 100% 0, 50% 100%)"}} backgroundColor="#4771DF" position="absolute" bottom={-20}></Box>
                            </Stack>
                        </Box>
                        <Box flexBasis="30%">
                            <Stack flexDirection="row" backgroundColor="#4771DF" p={2} borderRadius={5} alignItems="center">
                            <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/TOP_icon3.webp"} loading='lazy' alt="TOP_icon3" style={{width:50}}></Box>
                                <Box component="h3" flex={1} pr="50px">
                                    <Box fontSize={20}>設備投資で</Box>
                                    <Box fontSize={25}>生産性アップ</Box>
                                </Box>
                            </Stack>
                            <Stack justifyContent="center" alignItems="center" position="relative" >
                                <Box width="30px" height="calc(60px / 2)" sx={{clipPath:"polygon(0 0, 100% 0, 50% 100%)"}} backgroundColor="#4771DF" position="absolute" bottom={-20}></Box>
                            </Stack>
                        </Box>
                    </Stack>
                    <Stack fontSize={30} flexDirection={{sx:"column",md:"row"}} justifyContent="center" mt={{xs:3, md:8}} gap={3} color="white">

                        <Box flexBasis="30%">
                            <Stack flexDirection="row" backgroundColor="#4771DF" p={2} borderRadius={5} alignItems="center">
                                <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/TOP_icon4.webp"} loading='lazy' alt="TOP_icon4" style={{width:50}}></Box>
                                <Box component="h3" flex={1} pr="50px">
                                    <Box fontSize={20}>パート・アルバイトも</Box>
                                    <Box fontSize={25}>活躍</Box>
                                </Box>
                            </Stack>
                            <Stack justifyContent="center" alignItems="center" position="relative" >
                                <Box width="30px" height="calc(60px / 2)" sx={{clipPath:"polygon(0 0, 100% 0, 50% 100%)"}} backgroundColor="#4771DF" position="absolute" bottom={-20}></Box>
                            </Stack>
                        </Box>
                        <Box flexBasis="30%">
                            <Stack flexDirection="row" backgroundColor="#4771DF" p={2} borderRadius={5} alignItems="center">
                                <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/TOP_icon5.webp"} loading='lazy' alt="TOP_icon5" style={{width:50}}></Box>
                                <Box component="h3" flex={1} pr="50px">
                                    <Box fontSize={25}>ワークライフ</Box>
                                    <Box fontSize={20}><Box fontSize={25} component="span">バランス</Box>の実現</Box>
                                </Box>
                            </Stack>
                            <Stack justifyContent="center" alignItems="center" position="relative" >
                                <Box width="30px" height="calc(60px / 2)" sx={{clipPath:"polygon(0 0, 100% 0, 50% 100%)"}} backgroundColor="#4771DF" position="absolute" bottom={-20}></Box>
                            </Stack>
                        </Box>
                        
                    </Stack>
                </Box>
            </Box>
    )
}

export default TopSection4;