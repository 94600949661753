import React from 'react';
import {Box,Stack} from '@mui/material'
import ConversionButtonA from "../../Components/ConversionButtonA";
import ConversionButtonB from "../../Components/ConversionButtonB";

function EndSection(){

    return (
        <Box component="section" backgroundColor="#4771DF" textAlign="center" pt={10} pb={10}>
                <Box className='section-content' textAlign="center" color="white">
                    <Box fontSize={{xs:20, md:30}}>会社の<Box component="span" fontSize={{xs:30, md:45}} ml={1} mr={1}>労務課題</Box>と<Box component="span" fontSize={{xs:30, md:45}} ml={1} mr={1}>助成金</Box>のお悩み、</Box>
                    <Box fontSize={{xs:30, md:45}}>「助成金コーディネート」<Box component="span" fontSize={{xs:20, md:30}} mr={1}>で</Box>同時に解決<Box component="span" fontSize={{xs:20, md:30}}>しませんか？！</Box></Box>

                    <Stack flexDirection={{sx:"column",md:"row"}} justifyContent="space-evenly" mt={10} gap={5}>
                        
                        <Stack alignItems="center" gap={4}>
                            <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/CTA1.webp"} loading='lazy' alt="CTA1" width="100%" maxWidth={300}></Box>

                            <Box width="100%">
                                <Box fontSize={16} color="white">たった<Box component="span" fontSize={30}>５</Box>分で<Box component="span" fontSize={30}>カンタン</Box>!</Box>
                                <ConversionButtonA display="block" fontSize={{xs:20, md:30}} >助成金診断<Box component="span" ml={1} fontSize={{xs:16, md:20}}>する</Box></ConversionButtonA>
                            </Box>
                        </Stack>
                        
                        
                        <Stack alignItems="center" gap={4}>
                            <Box component="img" src={process.env.PUBLIC_URL+"/static/media/LP/CTA2.webp"} loading='lazy' alt="CTA2" width="100%" maxWidth={300}></Box>
                            <Stack flex={1} justifyContent="end" width="100%">
                                
                                <ConversionButtonB display="block" fontSize={{xs:20, md:30}}><Box component="span"></Box>プロに相談<Box component="span" ml={1} fontSize={{xs:16, md:20}}>する</Box></ConversionButtonB>
                            </Stack>
                        </Stack>
                        
                    </Stack>

                    <Box mt={10} fontSize={{xs:18, md:30}}>まずは、<br/><Box component="span" fontSize={{xs:30, md:45}} mr={1}>お気軽</Box>に<Box component="span" fontSize={{xs:30, md:45}} ml={1} mr={1}>お問合せ</Box>ください！</Box>
                </Box>
            </Box>
    )
}

export default EndSection;