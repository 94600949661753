import React from 'react';
import {Box} from '@mui/material'
import AccentBox from '../../Components/AccentBox';

function TopSection5(){

    return (
        <Box component="section" backgroundColor="#4771DF" pb={10}>
            <Box className='section-content' textAlign="center">
                
                <Box margin="0 auto" mt={-1} width="30%" height={{xs:"50px",md:"100px"}} sx={{clipPath:"polygon(0 0, 100% 0, 50% 100%)"}} backgroundColor="#F2F9FF" ></Box>
                <Box component="h1" mt={5} className='section-content' textAlign="center" color="white" fontWeight="bold" mb={3}>
                    <Box fontSize={{xs:18, md:20}}>そのような<Box component="br" display={{xs:"block",md:"none"}}/><Box component="span" fontSize={{xs:25, md:45}} ml={1} mr={1}><AccentBox>会社づくりをサポート</AccentBox><Box component="br" display={{xs:"block",md:"none"}}/></Box>してくれるのが</Box>
                    <Box fontSize={{xs:25, md:45}}>助成金<Box component="span">です！</Box></Box>
                </Box>
                <Box component="img" display={{xs:"none", md:"block"}} src={process.env.PUBLIC_URL+"/static/media/LP/TOP_joseikin1.webp"} loading='lazy' alt="TOP_joseikin1" width="100%"></Box>
                <Box component="img" display={{xs:"block", md:"none"}} src={process.env.PUBLIC_URL+"/static/media/LP/SF_TOP_joseikin1.webp"} loading='lazy' alt="TOP_joseikin1" width="100%"></Box>
            </Box>
        </Box>
    )
}

export default TopSection5;